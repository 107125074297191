<template>
  <div v-if="!loading" class="container searchTitle">
    {{ $t('result-page.title') }}: <strong>{{ search }}</strong
    ><br />
    <div class="quantity" v-if="search">
      {{ $t('result-page.quantity') }}: <strong>{{ total }}</strong>
    </div>
    <div class="fail" v-if="showFail">
      {{ $t('result-page.fail') }}
    </div>
  </div>
  <div v-else class="container searchTitle">
    <spinner></spinner>
  </div>
</template>

<script>
import Spinner from './Spinner'

export default {
  components: {
    Spinner
  },
  computed: {
    search() {
      return this.$store.state.search.term
    },
    searched() {
      return this.$store.state.search.searched
    },
    total() {
      var total =
        this.$store.state.search.list.highlights.length +
        this.$store.state.search.list.verticals.length +
        this.$store.state.search.list.events.length +
        this.$store.state.search.list.materials.length +
        this.$store.state.search.list.calendars.length +
        this.$store.state.search.list.institutionals.length +
        this.$store.state.search.list.saletools.length +
        this.$store.state.search.list.tickets.length +
        this.$store.state.search.list.box.length
      return total
    },
    showFail() {
      if (this.search && !this.total && this.searched) {
        return true
      }

      return false
    },
    loading() {
      return this.$store.state.search.loading
    }
  }
}
</script>
