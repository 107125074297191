<template>
  <div class="container result-section result-highlights" v-if="items.length">
    <h2>
      {{ $t('result-page.box') }}
    </h2>

    <div class="items">
      <div class="item" v-for="(item, index) in items" :key="index">
        <div class="data data-folder-file">
          <file-manager-file-thumb
            v-if="item.item.type === 'file'"
            :file="item.item"
          ></file-manager-file-thumb>
          <div class="data-folder-file__content">
            <div class="data-title">
              {{ item.title }}
            </div>
            <div class="data-link">
              <a :href="item.link" target="_blank">{{ item.link }}</a>
            </div>
            <div class="data-description">
              {{ item.description }}
              <br />
              <div class="data-actions">
                <a
                  v-if="!isDownloading(item)"
                  class="file-manager-file__button"
                  href="#"
                  @click.prevent="$emit('download', item.item)"
                >
                  <img src="/download.svg" />
                </a>
                <a
                  v-if="!isDownloading(item)"
                  class="file-manager-file__button"
                  href="#"
                  @click.prevent="$emit('open', item.item)"
                >
                  <img src="/external-link.svg" />
                </a>
                <spinner v-if="isDownloading(item)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ENVS from '../../env'
import Spinner from './Spinner'
import FileManagerFileThumb from './FileManagerFileThumb'

export default {
  props: {
    downloading: Number
  },
  components: {
    Spinner,
    FileManagerFileThumb
  },
  data: () => ({
    ENVS
  }),
  methods: {
    isDownloading({ item }) {
      return +item.id === this.downloading
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale.toUpperCase()
    },
    items() {
      return this.$store.state.search.list.box.map((item) => {
        item['title'] = item.item.name
        item['description'] = item.item.description
        item['link'] = item.item.url
        return item
      })
    }
  }
}
</script>
