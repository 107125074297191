<template>
  <div class="file-manager-file__thumb">
    <span v-if="thumb" class="file-manager-file__thumb__image">
      <img :src="thumb" :alt="file.name" />
    </span>
    <span v-else class="file-manager-file__thumb__extension">
      {{ extension }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    file: Object
  },
  computed: {
    extension() {
      const extension = this.file.name.split('.').pop()
      if (extension) {
        return extension.toUpperCase()
      }
      return '?'
    },
    thumb() {
      return this.file.thumb
    }
  }
}
</script>
