<template>
    <div class="container result-section result-highlights" v-if="items.length">
        <h2>
            {{ titleLabel }}
        </h2>

        <div class="items">
            <div
                class="item"
                v-for="(item, index) in items"
                :key="index">

                <div class="image"
                    @click="goTo(item.id)">
                    <img :src="item.image" v-if="item.image">
                </div>

                <div class="data">
                    <div class="data-title" @click="goTo(item.id)">
                        {{item.title}}
                    </div>
                    <div class="data-link">
                        <a :href="item.link" target="_blank">{{item.link}}</a>
                    </div>
                    <div class="data-description" @click="goTo(item.id)">
                        {{item.description}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ENVS from '../../env'

export default {
    props: {
        typeName: String,
    },
    data: () => ({
        ENVS
    }),
    computed: {
        locale() {
            return this.$i18n.locale.toUpperCase()
        },
        items() {
            return this.$store.state.search.list[this.typeName]
                .map( item => {
                    if( item.customFields.image ){
                        item['image'] = `${ENVS.API_URL}images/${item.customFields.image}`
                    } else {
                        item['image'] = false
                    }
                    item['title'] = item.fields.title[this.locale]
                    item['description'] = item.fields.description[this.locale]
                    item['link'] = item.fields.link[this.locale]
                    return item
                })
        },
        titleLabel() {
            return this.$t(`result-page.${this.typeName}`)
        }
    },
    methods: {
        goTo( id ){
            this.$router.push({
                name: 'pages-view',
                params: { id }
            })
        }
    }
}
</script>