<template>
  <div id="pages-search-result">
    <div class="container">
      <search-box />
      <search-result-title />
      <search-result-box :downloading="downloading" @download="onDownload" @open="onOpen" />
      <search-result-highlights type-name="highlights" />
      <search-result-verticals type-name="verticals" />
      <search-result-events type-name="events" />
      <search-result-verticals type-name="materials" />
      <search-result-highlights type-name="calendars" />
      <search-result-highlights type-name="institutionals" />
      <search-result-verticals type-name="saletools" />
      <search-result-highlights type-name="tickets" />
    </div>
  </div>
</template>

<script>
import SearchBox from '../../components/SearchBox.vue'
import SearchResultTitle from '../../components/SearchResultTitle.vue'
import SearchResultHighlights from '../../components/SearchResultHighlights.vue'
import SearchResultVerticals from '../../components/SearchResultVerticals.vue'
import SearchResultEvents from '../../components/SearchResultEvents.vue'
import SearchResultBox from '../../components/SearchResultBox.vue'

export default {
  components: {
    SearchBox,
    SearchResultTitle,
    SearchResultHighlights,
    SearchResultVerticals,
    SearchResultEvents,
    SearchResultBox
  },
  computed: {
    downloading() {
      return this.$store.state.box.downloading
    }
  },
  methods: {
    async onDownload(item) {
      const { id } = item
      await this.$store.dispatch('box/download', +id)
      this.$nextTick(() => {
        if (this.$store.state.box.downloads[id]) {
          document.location = this.$store.state.box.downloads[id]
        }
      })
    },
    onOpen (item) {
      window.open(item.url)
    }
  }
}
</script>
